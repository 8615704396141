import Vue from 'vue'
const Cookie = process.client ? require('js-cookie') : undefined
export default () => {
  const url = window.location.href
  if (url.indexOf('_ga') !== -1) {
    window.history.replaceState({}, '', url.slice(0, url.indexOf('_ga')))
  }
  if (url.indexOf('?rdtm_from') !== -1) {
    const fromIndex = url.indexOf('rdtm_from')
    const from = url.slice(fromIndex)
    let domain = ''
    if (url.indexOf('app.') !== -1) {
      const appIndex = url.indexOf('app.')
      const rdtmIndex = url.indexOf('?rdtm_from')
      domain = url.slice(appIndex + 3, rdtmIndex - 1)
    }
    Cookie.set('rd_GAB', from, { expires: 90, domain: domain })
  }
  if (Cookie.get('rd_GAB')) {
    const gab = Cookie.get('rd_GAB')
    const formEmd = gab.indexOf('&')
    const gabMap = new Map()
    gab.split('&').forEach(item => {
      const arr = item.split('=')
      gabMap.set(arr[0], arr[1])
    })
    localStorage.setItem('rdtm_from', gabMap.get('rdtm_from'))
    if (gab.indexOf('rdtm_keyword') !== -1) {
      const keywordIndex = gab.indexOf('rdtm_keyword')
      const keywordEnd = gab.indexOf('&', keywordIndex)
      const keyword = gab.slice(keywordIndex + 13, keywordEnd)
      localStorage.setItem('rdtm_keyword', keyword)
      localStorage.setItem('rdtm_other', gab.slice(keywordEnd + 1))
    } else {
      localStorage.setItem('rdtm_other', gab.slice(formEmd + 1))
    }
  }
  if (document.referrer.indexOf('www.rapiddirect.com') !== -1) {
    const visit = Cookie.get('rd_last_page_visit')
    const last = visit.slice(visit.indexOf('.com') + 4)
    localStorage.setItem('wwwlastpage', last)
    const session = Cookie.get('rd_usr_landing_session')
    localStorage.setItem('wwwfirstpage', session)
  }
}
const url = {
  install(Vue) {
    Vue.prototype.url = {
      setField: function(data) {
        if (localStorage.getItem('rdtm_from')) {
          data.rdtm_from = localStorage.getItem('rdtm_from')
          data.rdtm_keyword = localStorage.getItem('rdtm_keyword')
          data.rdtm_other = localStorage.getItem('rdtm_other')
        }
        if (localStorage.getItem('wwwlastpage')) {
          data.wwwlastpage = localStorage.getItem('wwwlastpage')
        }
        if (localStorage.getItem('wwwfirstpage')) {
          data.wwwfirstpage = localStorage.getItem('wwwfirstpage')
        }
      }
    }
  }
}
Vue.use(url)
