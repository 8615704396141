const cookieparser = process.server ? require('cookieparser') : undefined
const hasLogin = {}

export const state = () => ({
  avatar: null,
  auth: null,
  dialogLogin: false,
  register: false,
  thankYou: false,
  findPassword: false,
  resetPassword: false,
  message: false,
  tech: '1',
  aside: true,
  resize: 1920,
  checkQuoteStatus: 0,
  formData: '',
  loginType: '', //判断是从哪个操作进行登录的
  files: [],
  scale: 1,
  ossFiles: [],
  quoteKeyWords:/tolerance|fit|fits|±|um|coating(\s+)thickness|plating(\s+)thickness|fitting|fitted|assembly|assemble|assembled|assembling|gold-plating|platinum-plating/gi //报价备注关键字验证
})

export const mutations = {
  setOssFiles(state, files) {
    state.ossFiles = files
  },
  setFiles(state, files) {
    state.files = files
  },
  checkQuote(state, status) {
    state.checkQuoteStatus = Number(status)
  },
  setAuth(state, auth) {
    state.auth = auth
  },
  setAvatar(state, ava) {
    state.avatar = ava
    localStorage.setItem('avatar', ava)
  },
  setFormData(state, data) {
    state.formData = data
  },
  openLogin(state, dialogLogin) {
    state.dialogLogin = dialogLogin
  },
  openRegister(state, register) {
    state.register = register
  },
  openThankYou(state, thankYou) {
    state.thankYou = thankYou
  },
  openFindPassword(state, findPassword) {
    state.findPassword = findPassword
  },
  openResetPassword(state, resetPassword) {
    state.resetPassword = resetPassword
  },
  openMessage(state, message) {
    state.message = message
  },
  loginOk(state, login) {
    hasLogin.login = login
  },
  setLoginType(state, type) {
    state.loginType = type
  },
  setTech(state, tech) {
    state.tech = tech
  },
  setAside(state, aside) {
    state.aside = aside
  },
  setResize(state, resize) {
    state.resize = resize
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    let auth = null
    let checkQuoteStatus = null
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        auth = parsed.auth
        checkQuoteStatus = parsed.checkQuote
      } catch (err) {
        // 找不到有效的Cookie
      }
    }
    commit('setAuth', auth)
    commit('checkQuote', checkQuoteStatus)
  },
  wantLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      // 打开登录弹窗
      commit(`${data.loginType}`, data.visible)
      Object.defineProperty(hasLogin, 'login', {
        configurable: true,
        get: function() {
          return this.login
        },
        set: function(value) {
          resolve(value) // 发射回调
        }
      })
    })
  }
}
