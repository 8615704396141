<template>
  <el-main>
    <el-row v-if="error.statusCode === 404" type="flex">
      <el-col>
        <h1>Oops!</h1>
        <p>We could not find the page you were looking for</p>
        <nuxt-link to="/">
          <el-button type="primary">Go Back Home</el-button>
        </nuxt-link>
      </el-col>
      <el-col class="tc">
        <img src="@/assets/img/404.png" alt="404" />
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped>
.el-main {
  display: flex;
  justify-content: center;
  padding: 80px 20px;
}
.el-row {
  width: 1000px;
}
h1 {
  font-size: 56px;
  color: #000;
  margin: 64px 0 30px;
}
p {
  font-size: 20px;
  margin-bottom: 30px;
}
</style>
