import { getSolidWorkOssPart } from '@/libs/tool.js'

export async function createQuote(ctx) {
  return new Promise(async (resolve, reject) => {
    const processType = 1

    if (!ctx.$store.state.auth) {
      ctx.$router.push('/login/login')
      return reject()
    }
    let ossPart = null
    try {
      ossPart = await getSolidWorkOssPart(ctx)
    } catch (error) {
      return reject()
    }
    if (!ossPart) return reject()
    const res = await ctx.$quoteNo({
      process_type: processType
    })

    if (!res || res.code !== 1) {
      ctx.$notify({
        title: 'Error',
        message: 'create quote error!',
        type: 'error',
        customClass: 'notify-error'
      })
      return reject()
    }
    ctx.$store.commit('setOssFiles', [ossPart.data])
    ctx.$router.replace({
      path: '/quote',
      query: {
        quote_no: res.data.quote_no,
        type: 1,
        techgroup: processType
      }
    })
    resolve()
  })
}
