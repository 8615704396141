export const state = () => ({
  UnreadNum: 0,
  teamName: '',
  userName: '',
  email: '',
  url: '',
  firstName: '',
  lastName: ''
})

export const mutations = {
  setUnreadNum(state, data) {
    state.UnreadNum = data
  },
  setTeamName(state, data) {
    state.teamName = data
  },
  setUserName(state, data) {
    state.userName = data
  },
  setEmail(state, data) {
    state.email = data
  },
  setUrl(state, data) {
    state.url = data
  },
  setFirstName(state, data) {
    state.firstName = data
  },
  setLastName(state, data) {
    state.lastName = data
  }
}

export const actions = {
  getUrl({ commit }) {
    const url = window.location.host
    let index = url.indexOf('.')
    let num = 0
    let urldata = ''
    while (index !== -1) {
      num++
      index = url.indexOf('.', index + 1)
    }
    if (num === 2) {
      urldata = url.substr(url.indexOf('.') + 1)
    } else {
      urldata = url
    }
    commit('setUrl', urldata)
  }
}
