import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _96fe1c78 = () => interopDefault(import('..\\pages\\common.js' /* webpackChunkName: "pages/common" */))
const _5b10fa56 = () => interopDefault(import('..\\pages\\quote\\index.vue' /* webpackChunkName: "pages/quote/index" */))
const _275b1d92 = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _df20adfe = () => interopDefault(import('..\\pages\\login\\agreement.vue' /* webpackChunkName: "pages/login/agreement" */))
const _8eea9fde = () => interopDefault(import('..\\pages\\login\\forgotPwd.vue' /* webpackChunkName: "pages/login/forgotPwd" */))
const _cf2bf340 = () => interopDefault(import('..\\pages\\login\\login.vue' /* webpackChunkName: "pages/login/login" */))
const _fd139bae = () => interopDefault(import('..\\pages\\login\\mailVerify.vue' /* webpackChunkName: "pages/login/mailVerify" */))
const _588cf67c = () => interopDefault(import('..\\pages\\login\\register.vue' /* webpackChunkName: "pages/login/register" */))
const _207a8c81 = () => interopDefault(import('..\\pages\\login\\resetPassword.vue' /* webpackChunkName: "pages/login/resetPassword" */))
const _cf9c60ea = () => interopDefault(import('..\\pages\\quote\\mixins\\index.js' /* webpackChunkName: "pages/quote/mixins/index" */))
const _7e03c607 = () => interopDefault(import('..\\pages\\quote\\success.vue' /* webpackChunkName: "pages/quote/success" */))
const _3730f5d8 = () => interopDefault(import('..\\pages\\quote\\components\\configure.vue' /* webpackChunkName: "pages/quote/components/configure" */))
const _969fd158 = () => interopDefault(import('..\\pages\\quote\\components\\summary.vue' /* webpackChunkName: "pages/quote/components/summary" */))
const _124df10c = () => interopDefault(import('..\\pages\\quote\\components\\ww-popover.vue' /* webpackChunkName: "pages/quote/components/ww-popover" */))
const _a5f4a592 = () => interopDefault(import('..\\pages\\quote\\components\\ww-select.vue' /* webpackChunkName: "pages/quote/components/ww-select" */))
const _188ce8ec = () => interopDefault(import('..\\pages\\quote\\components\\ww-select1.vue' /* webpackChunkName: "pages/quote/components/ww-select1" */))
const _40c11e42 = () => interopDefault(import('..\\pages\\quote\\mixins\\configComputed.js' /* webpackChunkName: "pages/quote/mixins/configComputed" */))
const _4038f9b0 = () => interopDefault(import('..\\pages\\quote\\mixins\\imgagePrice.js' /* webpackChunkName: "pages/quote/mixins/imgagePrice" */))
const _c7c2a584 = () => interopDefault(import('..\\pages\\quote\\mixins\\indexComputed.js' /* webpackChunkName: "pages/quote/mixins/indexComputed" */))
const _01017581 = () => interopDefault(import('..\\pages\\quote\\mixins\\indexData.js' /* webpackChunkName: "pages/quote/mixins/indexData" */))
const _5d5addde = () => interopDefault(import('..\\pages\\quote\\mixins\\indexMethods.js' /* webpackChunkName: "pages/quote/mixins/indexMethods" */))
const _32063af6 = () => interopDefault(import('..\\pages\\quote\\mixins\\publicMixin.js' /* webpackChunkName: "pages/quote/mixins/publicMixin" */))
const _02a496d4 = () => interopDefault(import('..\\pages\\quote\\mixins\\saveBtnValid.js' /* webpackChunkName: "pages/quote/mixins/saveBtnValid" */))
const _46435ffc = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _4f32e26a = () => interopDefault(import('..\\pages\\index' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/common",
    component: _96fe1c78,
    name: "common"
  }, {
    path: "/quote",
    component: _5b10fa56,
    name: "quote"
  }, {
    path: "/test",
    component: _275b1d92,
    name: "test"
  }, {
    path: "/login/agreement",
    component: _df20adfe,
    name: "login-agreement"
  }, {
    path: "/login/forgotPwd",
    component: _8eea9fde,
    name: "login-forgotPwd"
  }, {
    path: "/login/login",
    component: _cf2bf340,
    name: "login-login"
  }, {
    path: "/login/mailVerify",
    component: _fd139bae,
    name: "login-mailVerify"
  }, {
    path: "/login/register",
    component: _588cf67c,
    name: "login-register"
  }, {
    path: "/login/resetPassword",
    component: _207a8c81,
    name: "login-resetPassword"
  }, {
    path: "/quote/mixins",
    component: _cf9c60ea,
    name: "quote-mixins"
  }, {
    path: "/quote/success",
    component: _7e03c607,
    name: "quote-success"
  }, {
    path: "/quote/components/configure",
    component: _3730f5d8,
    name: "quote-components-configure"
  }, {
    path: "/quote/components/summary",
    component: _969fd158,
    name: "quote-components-summary"
  }, {
    path: "/quote/components/ww-popover",
    component: _124df10c,
    name: "quote-components-ww-popover"
  }, {
    path: "/quote/components/ww-select",
    component: _a5f4a592,
    name: "quote-components-ww-select"
  }, {
    path: "/quote/components/ww-select1",
    component: _188ce8ec,
    name: "quote-components-ww-select1"
  }, {
    path: "/quote/mixins/configComputed",
    component: _40c11e42,
    name: "quote-mixins-configComputed"
  }, {
    path: "/quote/mixins/imgagePrice",
    component: _4038f9b0,
    name: "quote-mixins-imgagePrice"
  }, {
    path: "/quote/mixins/indexComputed",
    component: _c7c2a584,
    name: "quote-mixins-indexComputed"
  }, {
    path: "/quote/mixins/indexData",
    component: _01017581,
    name: "quote-mixins-indexData"
  }, {
    path: "/quote/mixins/indexMethods",
    component: _5d5addde,
    name: "quote-mixins-indexMethods"
  }, {
    path: "/quote/mixins/publicMixin",
    component: _32063af6,
    name: "quote-mixins-publicMixin"
  }, {
    path: "/quote/mixins/saveBtnValid",
    component: _02a496d4,
    name: "quote-mixins-saveBtnValid"
  }, {
    path: "/",
    component: _46435ffc,
    name: "index"
  }, {
    path: "/",
    component: _4f32e26a,
    name: "/"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
